<template>
  <div class="holy-places">
    <h1>Сделай город лучше</h1>
    <swiper class="swiper" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="place in places" :key="place.id">
        <holy-place
          :name="place.title"
          :img="place.images[Object.keys(place.images)[0]]"
          @click.native="goToObject(place)"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import HolyPlace from "@/components/Parts/SquareCard";

export default {
  name: "HolyPlaces",
  components: { HolyPlace },
  data() {
    return {
      places: []
    };
  },
  mounted() {
    api.getObjects(1, 1, null, null, 'small').then(res => {
      this.places = res.data;
    });
  },
  methods: {
    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
      this.$store.dispatch("fetchNearObjects", object.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.holy-places {
  .swiper-slide {
    width: 224px;
    margin-right: 24px;

    &:first-child {
      margin-left: 32px;
    }

    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>
