<template>
  <div class="tile">
    <div class="image">
      <div v-if="specProject" class="spec-project">
        <div class="spec-project__circle"></div>
        <span class="spec-project__title">
          спецпроект
        </span>
      </div>
      <img v-if="img" :src="require('@/assets/img/' + this.img)" />
      <span class="title">{{ title }}</span>
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TileBig",
  props: {
    specProject: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tile {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 356px;
    height: 96px;
    bottom: -64px;
    left: 0;
    -webkit-filter: blur(26px);
    filter: blur(26px);
    background-color: $color_shade;
  }

  .image {
    position: relative;
    z-index: 2;
    width: 356px;
    height: 526px;
    border-radius: 28px;
    overflow: hidden;
    background-color: $color_grey_3;

    .spec-project {
      position: absolute;
      top: 36px;
      left: 20px;

      &__circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #f9e600;
      }

      &__title {
        font-size: 40px;
        font-weight: 500;
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title {
      position: absolute;
      bottom: 42px;
      left: 24px;
      z-index: 2;
      max-width: 352px;
      font-size: 40px;
      font-weight: 500;
      line-height: 1.09;
      letter-spacing: -0.75px;
      color: rgba(#fff, 0.97);
    }

    .shadow {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.81) 202%);
    }
  }

  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }
}
</style>