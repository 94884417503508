<template>
  <div
    v-if="stories.length"
    class="saints" 
  >
    <swiper
      v-if="stories"
      class="swiper" 
      :options="{ 
        slidesPerView: 'auto',
        touchRatio: 2
      }" 
    >
      <swiper-slide v-for="story in stories" :key="story.id">
        <saint
          :name="story.title"
          :img="story.imgs[0].image.replace('extra','small')"
          @click.native="goToStory(story.id)"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Saint from "@/components/Parts/CircleCard";

export default {
  name: "Saints",
  components: { Saint },
  computed: {
    ...mapGetters(["stories"])
  },
  mounted() {
    this.$store.dispatch("fetchStoriesList");
  },
  methods: {
    goToStory(id) {
      this.$router.push({ path: `/story/${id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.saints {
  .swiper-slide {
    width: 224px;
    margin-right: 23px;

    &:first-child {
      margin-left: 32px;
    }

    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>